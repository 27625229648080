<template>
  <div class="outside">
    123

    <div class="content" ref="content">
      <el-input v-model="quality" placeholder="图片质量,不压缩为1,默认0.8"></el-input>
      <el-upload ref="upload" :beforeUpload="beforeUpload" accept="image/png,image/jpg,image/jpeg" class="upload-demo"
        drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
        <div v-if="!loading">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <br />
          <div class="el-upload__text">星链内部网站，请勿外传!!</div>
        </div>

        <div v-if="loading">
          <i class="el-icon-loading" style="font-size: 50px; margin-top: 20px"></i>
          <div class="el-upload__text">
            <em> 正在上传 </em>
          </div>
        </div>


      </el-upload>
    </div>
    <!-- 信息提示 -->
    <div v-if="files.length > 0" style="color: green" class="flex align-center">

      <div v-for="(item, index) in files" :key="index" @click="copyContent(item.url)">
        <el-link type="primary" style="width: 300px;word-break: break-word;">
          {{ item.url }}
        </el-link>
        <el-image style="width: 50px; height: 50px" :src="item.url" @click="closeImg" :preview-src-list="[item.url]">
        </el-image>
      </div>

      <i class="el-icon-delete del" @click="del">删除</i>
    </div>

    <div v-if="fileSize != false">上传后体积：{{ fileSize }}</div>
  </div>
</template>

<script>
//导入七牛
import axios from "axios";

export default {
  props: ["value"],
  data() {
    return {
      files: [], //存储上传的文件名称和地址
      token: "", //上传到七牛云时要使用的token
      loading: false,
      fileSize: 0,
      quality: 0.8
    };
  },
  mounted() {
    if (this.value)
      this.files.push({
        url: this.value,
      });
  },
  methods: {
    beforeUpload(file) {
      console.log("压缩前体积：", file.size / 1024, "kb");
      // 压缩功能
      this.compressImg(
        file,
        {
          quality: this.quality,
        },
        (file) => {
          console.log("压缩后体积：", file.size / 1024, "kb");
          this.fileSize = file.size / 1024 + "kb";
          this.loading = true;
          this.uploadImg(file);
        }
      );
    },

    async uploadImg(file) {
      const formData = new FormData();
      // 遍历当前临时文件List,将上传文件添加到FormData对象中
      formData.append("file", file);
      // 调用后端接口,发送请求
      const res = await axios.post(
        "https://sxjm.starxin.link/tc1/dyh/singleImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      if (res.data.code != 200) {
        this.$message({
          message: "服务器错误",
          type: "error",
        });
        return;
      }
      let img = res.data.data;
      let url = `${img}?R=${(
        img.image_height / img.image_width
      ).toFixed(2)}`;

      this.files.push({ url: img })
      this.loading = false;
      this.$emit("input", url);
    },

    closeImg() {
      this.$nextTick(() => {
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },

    // copyContent(content) {
    //   navigator.clipboard.writeText(content);
    //   this.$message("复制成功");
    // },
    copyContent(text) {
      var input = document.createElement("input"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message({
        message: "复制成功^.^",
        type: "success",
      });
    },
    catchtap() { },
    del() {
      this.files = [];
      this.fileSize = false;
      this.$message({
        message: "删除成功",
        type: "warning",
      });
    },

    compressImg(file, obj, callback) {
      //https://blog.csdn.net/qq_40591925/article/details/124981698

      let _this = this;
      var ready = new FileReader();
      /*开始读取指定的Blob对象或File对象中的内容.
      当读取操作完成时,readyState属性的值会成为DONE,
      同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file);
      ready.onload = function () {
        var path = this.result;

        var img = new Image();
        img.src = path;
        img.onload = function () {
          var that = this;
          // 默认按比例压缩
          var w = that.width,
            h = that.height,
            scale = w / h;
          w = obj.width || w;
          h = obj.height || w / scale;
          var quality = 0.7; // 默认图片质量为0.7
          //生成canvas
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          //创建属性节点
          var anw = document.createAttribute("width");
          anw.nodeValue = w;
          var anh = document.createAttribute("height");
          anh.nodeValue = h;
          canvas.setAttributeNode(anw);
          canvas.setAttributeNode(anh);
          ctx.drawImage(that, 0, 0, w, h);
          //设置图像质量 值越小，则越模糊
          if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
            quality = obj.quality;
          }
          var base64 = canvas.toDataURL("image/jpeg", quality);
          //转换为Blob数据
          var BlobData = _this.convertBase64UrlToBlob(base64);
          //转换为file数据
          let this_file = new File([BlobData], file.name);
          //回调函数返回file
          callback(this_file);
        };
      };
    },
    convertBase64UrlToBlob(urlData) {
      // 转换为Blob数据方法
      var arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>

<style scoped>
.del {
  color: red;
  margin-left: 20px;
  cursor: pointer;
}
</style>