<template>
  <div id="app">
    <oss />
  </div>
</template>

<script>
import oss from "./components/oss";
// import demo from "./view/demo/demo";
export default {
  name: "App",
  components: { oss },
  mounted() {
  },
};
</script>

<style>
#app{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
</style>
